import axios from 'axios';

// Константа для базового URL API
// TODO ПРОВЕРЬ URL ПЕРЕД ПУШЕМ
const API_URL = 'https://api.jardin-spar.testpromo.ru';
// const API_URL = 'https://api.jardin-sp.ru:30130';

// Функция для установки токена в заголовок Authorization
export const setAuthHeader = () => {
    const token = getToken();
    if (token) {
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    } else {
        delete axios.defaults.headers.common['Authorization'];
    }
};

// Добавляем интерцептор для обработки ответов
axios.interceptors.response.use(
  response => response,
  error => {
      if (error.response && error.response.status === 401) {
          removeToken();
          delete axios.defaults.headers.common['Authorization'];
      }
      return Promise.reject(error);
  }
);

export const startGame = async () => {
    const response = await axios.post(`${API_URL}/api/game/start-game`);
    return response.data;
};

export const getQuestion = async (token) => {
    const response = await axios.post(`${API_URL}/api/game/start-game`, { token });
    return response.data;
};

export const submitAnswer = async (token, questionId, answerId) => {
    const response = await axios.post(`${API_URL}/api/game/set-answer`, {
        token,
        question_id: questionId,
        answer_id: answerId
    });
    return response.data;
};

export const getWinnings = async () => {
    try {
        setAuthHeader();
        const response = await axios.get(`${API_URL}/api/quiz/winnings`);
        return response.data;
    } catch (error) {
        console.error('Error fetching winnings by week', error);
        throw error;
    }
};

// Функция для выполнения запроса на получение данных о призах
export const getWinningPrizes = async () => {
    try {
        setAuthHeader();
        const response = await axios.get(`${API_URL}/api/quiz/winning-prizes`);
        return response.data;
    } catch (error) {
        console.error('Error fetching winning prizes', error);
        throw error;
    }
};

// Функция для получения списка призов
export const getPrizes = async () => {
    try {
        const response = await axios.get(`${API_URL}/api/quiz/prizes`);
        return response.data.results; // Возвращаем только массив результатов
    } catch (error) {
        console.error('Error fetching prizes:', error);
        throw error;
    }
};

// Функция для выполнения запроса на получение чеков пользователя
export const getUserChecks = async (offset, limit) => {
    try {
        setAuthHeader();
        const response = await axios.get(`${API_URL}/api/quiz/receipts?offset=${offset}&limit=${limit}`);
        return response.data;
    } catch (error) {
        console.error('Error fetching winning prizes', error);
        throw error;
    }
};

// Функции для работы с токенами, пользователем, настройками и т.д. остаются такими же, как у вас в текущем файле запросов
export const getToken = () => localStorage.getItem('token');

export const setToken = (token) => localStorage.setItem('token', token);

export const removeToken = () => localStorage.removeItem('token');

export const getUserInfo = async () => {
    try {
        setAuthHeader();
        const response = await axios.get(`${API_URL}/api/users/me`);
        return response.data;
    } catch (error) {
        console.error('Error fetching user info', error);
        throw error;
    }
};

export const authorize = async (phone_number, password, token) => {
    try {
        const response = await axios.post(`${API_URL}/api/users/authorization`, {
            phone_number,
            password,
            token,
        });
        setToken(response.data.access);
        setAuthHeader();
        return response.data;
    } catch (error) {
        console.error('Ошибка во время авторизации', error);
        throw error;
    }
};

export const register = async (first_name, last_name, phone_number, email) => {
    try {
        await axios.post(`${API_URL}/api/users/registration`, {
            first_name,
            last_name,
            phone_number,
            email,
        });
    } catch (error) {
        console.error('Error during registration', error);
        throw error;
    }
};

export const changeProfile = async (first_name, last_name, email) => {
    try {
        await axios.put(`${API_URL}/api/users/me`, {
            first_name,
            last_name,
            email,
        });
    } catch (error) {
        console.error('Error during profile update', error);
        throw error;
    }
};

export const changePassword = async (new_password) => {
    try {
        await axios.patch(`${API_URL}/api/users/change-password`, {
            new_password
        });
    } catch (error) {
        console.error('Error during profile update', error);
        throw error;
    }
};

export const getMainSettings = async () => {
    try {
        const response = await axios.get(`${API_URL}/api/main/settings`);
        return response.data;
    } catch (error) {
        console.error('Error fetching main settings', error);
        throw error;
    }
};

export const fetchFAQ = async () => {
    const endpoint = `${API_URL}/api/feedback/faq?limit=80`;
    try {
        const response = await axios.get(endpoint);
        return response.data;
    } catch (error) {
        console.error("Error fetching FAQ:", error);
        throw error;
    }
};

export const sendFeedback = async (message) => {
    const endpoint = `${API_URL}/api/feedback/send`;
    const formData = new FormData();

    formData.append('email', message.email);
    formData.append('name', message.name);
    formData.append('text', message.text);

    try {
        const response = await axios.post(endpoint, formData);
        return response.data;
    } catch (error) {
        console.error('Error sending feedback:', error.response);
        throw error;
    }
};

export const sendResetPassword = async (phone) => {
    const endpoint = `${API_URL}/api/users/recovery-password`;
    const formData = new FormData();

    formData.append('phone_number', phone.phone_number);

    try {
        const response = await axios.post(endpoint, formData);
        return response.data;
    } catch (error) {
        console.error('Error sending feedback:', error.response);
        throw error;
    }
};

export default {
    getToken,
    setToken,
    removeToken,
    getUserInfo,
    authorize,
    register,
    changeProfile,
    getMainSettings,
    getWinningPrizes, // Добавляем функцию для получения данных о призах
    fetchFAQ,
    sendFeedback,
    sendResetPassword,
};